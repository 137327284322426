"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var useCalendarDate = function (profile) {
    var toMonth = (0, react_1.useCallback)(function (calendarDate, format) {
        if (!calendarDate)
            return '';
        var date = new Date();
        date.setDate(1);
        date.setMonth(calendarDate.month - 1);
        return date.toLocaleString(profile.settings.interfaceLanguage, {
            month: format,
        });
    }, [profile.settings]);
    var toMonthYear = (0, react_1.useCallback)(function (calendarDate) {
        if (!calendarDate)
            return '';
        var date = new Date();
        date.setDate(1);
        date.setMonth(calendarDate.month - 1);
        date.setFullYear(calendarDate.year);
        return date.toLocaleString(profile.settings.interfaceLanguage, {
            month: 'long',
            year: 'numeric',
        });
    }, [profile.settings]);
    var toDayMonth = (0, react_1.useCallback)(function (calendarDate) {
        if (!calendarDate)
            return '';
        var date = new Date();
        date.setDate(calendarDate.day);
        date.setMonth(calendarDate.month - 1);
        return date.toLocaleString(profile.settings.interfaceLanguage, {
            day: 'numeric',
            month: 'short',
        });
    }, [profile.settings]);
    return {
        toMonth: toMonth,
        toMonthYear: toMonthYear,
        toDayMonth: toDayMonth,
    };
};
exports.default = useCalendarDate;
