"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var createId = function () { return Math.floor(Math.random() * 9999) + 1; };
var useShell = function () {
    var _a;
    var transport = (_a = globalThis === null || globalThis === void 0 ? void 0 : globalThis.qt) === null || _a === void 0 ? void 0 : _a.webChannelTransport;
    var send = function (method) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        transport === null || transport === void 0 ? void 0 : transport.send(JSON.stringify({
            id: createId(),
            type: 6,
            object: 'transport',
            method: 'onEvent',
            args: __spreadArray([method], args, true),
        }));
    };
    return {
        active: !!transport,
        send: send,
    };
};
exports.default = useShell;
