"use strict";
// Copyright (C) 2017-2024 Smart code 203358507
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var useOutsideClick = function (callback) {
    var ref = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(function () {
        var handleClickOutside = function (event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };
        document.addEventListener('mouseup', handleClickOutside);
        document.addEventListener('touchend', handleClickOutside);
        return function () {
            document.removeEventListener('mouseup', handleClickOutside);
            document.removeEventListener('touchend', handleClickOutside);
        };
    }, [callback]);
    return ref;
};
exports.default = useOutsideClick;
